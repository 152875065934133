import { render, staticRenderFns } from "./coupon.vue?vue&type=template&id=49b9ab50&scoped=true&"
import script from "./coupon.vue?vue&type=script&lang=js&"
export * from "./coupon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49b9ab50",
  null
  
)

export default component.exports